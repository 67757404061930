.App {
  text-align: center;
  background-color: #f0f0f0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.uuid-container {
  border: 5px solid;
  border-radius: 40px;
  padding: 10px 50px;
  margin-bottom: 80px;
  display: inline-block;
  text-align: center;
}

.uuid {
  font-size: 3.5rem;
  word-break: break-all;
  font-weight: bold;
}

@media (max-width: 768px) {
  .uuid {
    font-size: 13px; /* Font size for mobile */
  }
  button {
    font-size: 12px;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.icon-container {
  background-color: white;
  padding: 7px 10px;
  border-radius: 25px;
  margin-left: 20px;
}

button {
  font-size: 1.5rem;
  padding: 10px 10px 10px 20px;
  border: none;
  border-radius: 40px;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

button:hover {
  opacity: 0.9;
}